<template>
  <ClientOnly>
    <div class="app-header-sticky" :class="{show:y>=78}">
      <div class="container" v-show="y>=78">
        <div class="logo">
          <NuxtLink to="/"></NuxtLink>
        </div>
        <AppHeaderNav/>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup>
const {y} = useWindowScroll()
</script>

<style scoped lang='less'>
.app-header-sticky {
  width: 100%;
  height: 80px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #fff;
  border-bottom: 1px solid #e4e4e4;
  transform: translateY(-100%);
  opacity: 0;

  &.show {
    transition: all 0s linear;
    transform: none;
    opacity: 1;
  }

  .container {
    display: flex;
    align-items: center;

    .logo {
      width: 200px;
      height: 80px;
      display: block;
      text-indent: -9999px;
      position: relative;
      z-index: 1000;
      background: url('../assets/images/logo.png') no-repeat center 18px / contain;
    }
  }

  .right {
    width: 220px;
    display: flex;
    text-align: center;
    padding-left: 40px;
    border-left: 2px solid @baseColor;

    a {
      width: 38px;
      color: black;
      margin-right: 40px;
      font-size: 16px;
      line-height: 1;

      &:hover {
        color: @baseColor;
      }
    }
  }
}
</style>
