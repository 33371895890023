<template>
  <ClientOnly>
    <div v-if="isFullWindow">
      <ul class="app-header-nav">
        <li v-for="item in list" :key="item.count" :class="{active:item.count && $route.params.id===item.count}"
            @mousemove="show(item)" @mouseleave="hide(item)">
          <NuxtLink :style="{'color' : y===0&&route.path==='/'?'white':'black'}" @click="hide(item)"
                  :to="item.path || '/'">{{ item.name }}
          </NuxtLink>
          <div class="layer" :class="{open:item.open}" :style="{height: compete(item.children.length)+'px !important'}"
               v-if="item.children">
            <ul>
              <li v-for="sub in item.children" :key="sub.count">
                <NuxtLink @click="hide(item)" :to="sub.path || '/'">
                  <p>{{ sub.name }}</p>
                </NuxtLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div v-else style="width: 100%">
      <div style="width: 100%;margin-right: 0;margin-left: 90%;">
        <el-button type="primary" :with-header="false" @click="drawer=true">
          <font-awesome-icon icon="fa-solid fa-align-justify" style="z-index: 101"/>
        </el-button>
      </div>
      <el-drawer
        v-model="drawer"
        direction='rtl'
        size="50%"
      >
        <el-collapse accordion>
          <el-collapse-item v-for="item in list" :key="item.count" :title="item.name" :name="item.name"
                            @click.native="checkItem(item)">
            <div v-if="item.children">
              <el-menu>
                <el-menu-item v-for="sub in item.children" :key="sub.count">
                  <NuxtLink @click="hide(item)" :to="sub.path || '/'">
                    <p>{{ sub.name }}</p>
                  </NuxtLink>
                </el-menu-item>
              </el-menu>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-drawer>
    </div>
    <template #fallback>
      <div></div>
    </template>
  </ClientOnly>
</template>

<script setup>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const compete = (length) => {
  return (Number)(length * 32.5)
}

const store = useNuxtApp().$store
// 拿到vuex中的分类列表
const list = computed(() => {
  return store.state.menu.list
})
const isFullWindow = computed(() => store.state.windowScreen.isFullWindow)

// 跳转的时候不会关闭二级类目，通过数据来控制
const show = (item) => {
  store.commit('menu/show', item.count)
}

const drawer = ref(false)
const hide = (item) => {
  store.commit('menu/hide', item.count)
  drawer.value = false
}
const route = useRoute()
const router = useRouter()
const checkItem = (item) => {
  console.log(item)
  if (!item.children) {
    hide(item)
    router.push(item.path)
  }
}
const { y } = useWindowScroll()
</script>

<style scoped lang="less">
.app-header-nav {
  width: 60vw;
  display: flex;
  justify-content: space-around;
  padding-left: 40px;
  position: relative;
  z-index: 999;

  > li {
    text-align: center;

    > a {
      font-size: 16px;
      line-height: 32px;
      height: 32px;
      display: inline-block;
    }

    &:hover, &.active {
      > a {
        color: @baseColor;
        border-bottom: 1px solid @baseColor;
      }
    }
  }
}

.layer {
  width: 100px;
  background-color: #fff;
  position: fixed;
  top: 70px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  box-shadow: 0 0 5px #ccc;
  transition: all .2s .1s;

  &.open {
    opacity: 1;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
      width: 100%;
      text-align: center;

      img {
        width: 60px;
        height: 60px;
      }

      p {
        padding-top: 10px;
      }

      &:hover {
        p {
          color: @baseColor;
        }
      }
    }
  }
}
</style>
