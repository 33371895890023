<template>
  <!-- 头部组件 -->
  <AppHeader/>
  <!-- 吸顶头部 -->
  <AppHeaderSticky/>
  <!-- 内容容器 -->
  <div class="app-body">
    <!-- 页面内容 -->
    <slot />
  </div>
  <!-- 底部组件 -->
  <AppFooterTop/>
  <AppFooter/>
</template>

<script setup>
import {useStore} from 'vuex'
import {onMounted, onUnmounted, ref} from 'vue'

// 获取下分类数据
const store = useStore()

// 使用ref存储事件处理函数
const handleResize = ref(() => {
  const screenWidth = window.innerWidth
  console.log("宽度 大小: ", screenWidth)
  store.dispatch('windowScreen/updateSmallWindow')
})

onMounted(() => {
  store.dispatch('menu/getList')
  
  // 添加事件监听器
  window.addEventListener('resize', handleResize.value)
  // 初始调用一次
  handleResize.value()
})

onUnmounted(() => {
  // 移除事件监听器
  window.removeEventListener('resize', handleResize.value)
})
</script>

<style scoped lang="less">
.app-body {
  min-height: 600px;
  width: 160vh;
  margin: auto;
  background-color: #ffffff;
}
</style>
