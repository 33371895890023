<template>
  <header class="app-header">
    <div class="container">
      <ClientOnly>
        <div v-if="y===0&&route.path==='/'" class="logo1">
          <NuxtLink to="/"></NuxtLink>
        </div>
        <div v-else class="logo2">
          <NuxtLink to="/"></NuxtLink>
        </div>
        <template #fallback>
          <div class="logo2">
            <NuxtLink to="/"></NuxtLink>
          </div>
        </template>
      </ClientOnly>
      <!-- 使用头部导航组件 -->
      <AppHeaderNav />
    </div>
  </header>
</template>

<script setup>
const route = useRoute()
const { y } = useWindowScroll()
const store = useNuxtApp().$store;
</script>

<style scoped lang='less'>
.app-header {
  background: #fff;
  .container {
    display: flex;
    align-items: center;
  }
  .logo1 {
    width: 200px;
    a {
      display: block;
      height: 100px;
      width: 100%;
      text-indent: -9999px;
      position: relative;
      z-index: 1000;
      background: url('../assets/images/logo1.png')  no-repeat center 18px / contain;
    }
  }

  .logo2 {
    width: 200px;
    a {
      display: block;
      height: 100px;
      width: 100%;
      text-indent: -9999px;
      position: relative;
      z-index: 1000;
      background: url('../assets/images/logo.png')  no-repeat center 18px / contain;
    }
  }
}
</style>
